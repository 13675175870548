<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      تكملة البيانات
    </dashboard-page-title>
<!--    {{storeActive}}-->
     <form @submit.prevent="save">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات التاجر</h5>
                </template>
                <b-row>
                    <b-col  cols="12">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        placeholder="أدخل الاسم" label="الإسم"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.phone"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 0109564546" label="رقم الجوال"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.national_id"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 454846511154" label="رقم الهوية"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> المتجر</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-5" v-for="(item, key) in market.social_media_links" :key="key">
                    <b-form-group
                                               :label-for="'URL Links'"
                        class="position-relative"
                    >
              <span class="text-danger deleteLabelButton cursor-pointer" v-if="key != 0" @click="deleteLink(key)">مسح
              </span>
                      <b-input-group class="d-flex gap_1">
                        <validation-provider
                            #default="{ errors }"
                            :name="`URL Link ${key + 1}`"
                            :rules="{ required:true }"
                            class="flex-grow-1"
                        >
                          <b-form-input
                              id="mm"
                              v-model="item.link"
                              :class="[{ 'is-invalid': errors.length > 0 }]"
                              :placeholder="'Ex: https://www.google.com'"
                              :disabled="!item.selectSocial"
                          />
                        </validation-provider>
                        <template #prepend class="w-25">
                          <b-dropdown
                              :text="item.selectSocial ? item.selectSocial : 'Choose  '"
                              class="selectWithInput iq-border-radius-5 bg-white border"
                              variant="white"
                          >
                            <b-dropdown-item v-for="(i, keyLink) in filterLinks" :key="keyLink" @click="item.selectSocial = i">
                              {{i}}
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="allLinks.length !== market.social_media_links.length"><span class="text-warning cursor-pointer" @click="addNewLink">+ أضف منصة أخرى</span></b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-store"></i> معلومات المتجر</h5>
                </template>
                <b-row>
                  <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.ar_name"
                        validate="required"
                        name="id"
                        placeholder="أدخل الاسم" :label="$t('admins.storeNameInArabic')"
                    />
                  </b-col>
                  <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.en_name"
                        validate="required"
                        name="id"
                        placeholder="أدخل الاسم" :label="$t('admins.storeNameInEnglish')"
                    />
                  </b-col>
                  <!-- <b-col cols="6">
                  <label class="joining-label">{{ $t('admins.TypeOfServiceProvided') }}</label>
                  <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.category.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        placeholder=""
                  />
                  </b-col> -->
                  <b-col cols="12">
                  <label class="joining-label">{{ $t('admins.StoreType') }}</label>
                  <main-select
                  class="mb-3 joining-label"
                  :validate="'required'"
                  :name="$t('admins.storeType')"
                  :reduce="category => category.id"
                  :options="allStores"
                  v-model="storeActive.category.name"
                  label="name"
                  dir="rtl"
                />
                <!-- <input-form
                        class="mb-3 joining-label"

                        validate="required"
                        name="id"
                        :disabled="true"
                        placeholder=""
                /> -->
                  </b-col>
                  <b-col md="3">
                  <label class="joining-label">{{ $t('admins.theCity') }}</label>
                  <main-select
                  class="mb-3 joining-label"
                  :validate="'required'"
                  :name="$t('admins.theCity')"
                  :reduce="city => city.id"
                  :placeholder="$t('admins.theCity')"
                  :options="allCity"
                  v-model="storeActive.city.id"
                  label="name"
                  dir="rtl"
                 />
                  </b-col>
                  <b-col md="9">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.address"
                        :validate="'required'"
                        name="id"
                        label="العنوان"
                        placeholder="العنوان"
                    />
                  </b-col>
                  <b-col md="12">
                    <textarea-form placeholder="الوصف" v-model="market.about" label="عن المتجر" name="about" validate="required"></textarea-form>
                  </b-col>
                  <b-col md="12">
                    <textarea-form placeholder="الوصف" v-model="market.policies" label="الشروط و الأساسيات" name="policies" validate="required"></textarea-form>
                  </b-col>
<!--                  <b-col md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.category.name"
                        :validate="'required'"
                        name="id"
                        :disabled="true"
                        label="نوع المتجر"
                        placeholder="نوع المتجر"
                    />
                  </b-col>-->
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="شعار المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveLogoImage"
                        :progressLoading="progressLogo"
                        :multi="false"
                        :imageUrl="market.logo"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="غلاف المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCoverImage"
                        :progressLoading="progressCover"
                        :showProgress="true"
                        :multi="false"
                        :imageUrl="market.cover"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="السجل التجارى"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCommercialRegistry"
                        :progressLoading="progressCommercialRegistry"
                        :showProgress="true"
                        :multi="false"
                        :imageUrl="market.commercial_registry"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="البطاقة الضريبية"
                        nameOfImage="image.jpg"
                        @cropper-save="saveVatCertificate"
                        :progressLoading="progressVatCertificate"
                        :showProgress="true"
                        :multi="false"
                        :imageUrl="market.vat_certificate"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  النشر
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                  <b-button variant="primary"  class="w-100" type="submit" > حفظ </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </form>
  </div>
</template>
<script>
/* eslint-disable */ 
import { core } from '@/config/pluginInit'
import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
export default {
  mixins: [mainInfoMixins],
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      allCity:[],
      allStores:[],
      allLinks: [
        'Website',
        'Facebook',
        'Instagram'
      ],
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user : {},
      market: {
        logo: '',
        cover: '',
        commercial_registry: '',
        vat_certificate: '',
        status: 'processing',
        social_media_links: [
          {
            selectSocial: '',
            link: ''
          }
        ],
        about: '',
        policies: ''
      },
      loadingSubmitForm: false,
      progressCover: 0,
      progressLogo: 0,
      progressCommercialRegistry: 0,
      progressVatCertificate: 0
    }
  },
  computed: {
    filterLinks () {
      var newLinksArr = [...this.allLinks]
      this.market.social_media_links.forEach(e => {
        if (newLinksArr.includes(e.selectSocial)) {
          var socialIndex = newLinksArr.findIndex(social => social === e.selectSocial)
          newLinksArr.splice(socialIndex, 1)
        }
      })
      return newLinksArr
    }
  },

  methods: {
    getAllCities () {
      settingsServices.getAllCities().then(res => {
        this.allCity = res.data.data
       });
    },
    getAllStores () {
      settingsServices.getAllStores().then(res => {
        this.allStores=res.data.data
        this.allStores.pop()
      })
    },
    saveLogoImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'stores')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressLogo = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.market.logo = res.data.url
        this.showSuccessUploadFile()
      })
    },
    saveCoverImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'stores')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressCover = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.market.cover = res.data.url
        this.showSuccessUploadFile()
      })
    },
    saveCommercialRegistry (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'merchants')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressCommercialRegistry = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.market.commercial_registry = res.data.url
        this.showSuccessUploadFile()
      })
    },
    saveVatCertificate (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'merchants')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressVatCertificate = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.market.vat_certificate = res.data.url
        this.showSuccessUploadFile()
      })
    },
    addNewLink () {
      this.market.social_media_links.push({
        selectSocial: '',
        link: ''
      })
    },
    deleteLink (key) {
      this.market.social_media_links.splice(key, 1)
    },
    save () {
      // let storeActiveData ={category_id:this.storeActive.category.id}
      let storeActiveData = {
        en_name:this.storeActive.en_name , 
        ar_name:this.storeActive.ar_name , 
        city_id:this.storeActive.city.id ,
        category_id:this.storeActive.category.id ,
        address:this.storeActive.address
      } 

      // cloning deep "multilevel" object into another object " 
      let concatData = {
            ...structuredClone(this.market),
            ...structuredClone(storeActiveData)
      };
      this.loadingSubmitForm = true
      settingsServices.uploadMarketplaceFiles(concatData).then(res => {
        core.showSnackbar('success', 'Data Updated Successfully')
        this.getInfo()
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created() {
    // Fetch cities and stores when component mounts
    this.getAllCities()
    this.getAllStores () 
   

  }

}
</script>
<style>
.main-actions {
  position: sticky !important;
  top: 0px;
}
.deleteLabelButton {
  position: absolute;
  left: 10px;
  top: -30px;
}
</style>
